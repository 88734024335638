


















































import {
  SfButton, SfCheckbox, SfLink, SfTabs, SfLoader
} from '~/components';
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUiNotification } from '~/composables';

export default defineComponent({
  name: 'MyNewsletter',
  components: {
    SfTabs,
    SfCheckbox,
    SfButton,
    SfLink,
    SfLoader,
  },
  setup() {
    const { app: { i18n } } = useContext();
    const {
      user,
      updateUser,
      isAuthenticated,
      loading
    } = useUser();
    const { send: sendNotification } = useUiNotification();

    const isSubscribed = ref<boolean>(!!user.value.is_subscribed);
    const message = ref<String>('');

    const saveForm = async () => {
      if (isAuthenticated.value && !!user.value.email) {
        await updateUser({ user: { is_subscribed: isSubscribed.value } });

        if (isSubscribed.value) {
          message.value = 'You have successfully signed up for our newsletter.';
        } else {
          message.value = 'Your newsletter subscription has been cancelled';
        }

        sendNotification({
          id: Symbol('user_updated'),
          message: i18n.t(`${message.value}`) as string,
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'User Account',
        });

        isSubscribed.value = !!user.value.is_subscribed;
      }
    };

    return {
      isSubscribed,
      saveForm,
      loading,
    };
  },
});
